@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.App {
  font-family: "Montserrat", sans-serif;
}

@media screen and (min-width: 2000px) {
  .main{
    padding-left:30rem;
    padding-right:30rem; 
    background-color:#181818
  }
  
}

/* Header  */
.headerMain {
  padding: 20px;
  height: 30px;
  background-color: #121212;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.firstThreeButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.headerTextName {
  font-size: 25px;
  color: white;
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
}
.headerTextName:hover {
  font-size: 25px;
  color: grey;
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
}

.headerTextSkills,
.headerTextWork,
.headerTextContact {
  font-size: 17px;
  color: grey;
  padding-left: 20px;
  padding-top: 8px;
  font-family: "Montserrat", sans-serif;
}

.headerTextSkills:hover,
.headerTextWork:hover,
.headerTextContact:hover   {
  font-size: 17px;
  color: white;
  padding-left: 20px;
  padding-top: 8px;
  font-family: "Montserrat", sans-serif;
}
.headerTextHireMe {
  font-size: 17px;
  color: grey;
  padding-left: 20px;
  padding-top: 8px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  display: flex;
}

.gitHubIcon {
  width: 45px;
  height: 45px;
  color: white;
}
.gitHubIcon:hover {
  width: 45px;
  height: 45px;
  color: grey;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .headerTextName {
    color: white;
    padding-left: 15px;
    font-family: "Montserrat", sans-serif;
  }
  .headerTextSkills,
  .headerTextWork,
  .headerTextContact  {
    display: none;
  }
}
/* Header  */

/* Home Page */
.homeMain {
  background-color: #181818;
  width: 100%;
  height: 150%;
  display: flex;
  flex-direction: column;
}
.welcomeText {
  font-size: 30px;
  color: white;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
}

.hiJames {
  font-size: 20px;
}

.introText {
  padding-top: 50px;
  padding-left: 45px;
  flex-grow: 2;
}
.introTextMain {
  margin-top: 50px;
}
.introSubText {
  font-size: 20px;
  color: gray;
  font-family: "Montserrat", sans-serif;
}
.jamesPicture {
  border-radius: 25%;
  width: 350px;
  margin-top: 40px;
  align-self: flex-end;
  flex-grow: 1;
  margin-right: 50px;
  /* margin-left: 400px; */
}


@media screen and (max-width: 1024px) {
  .jamesPicture {
    display: none;
  }
}
/* Home Page */

/* Projects Page */

.projectsMain {
  margin-top: 10%;
  height: 100%;
}

.projectsTitle,
.projectsTag {
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.projectsTitle {
  margin-bottom: 200px;
}

.projectsTag {
  color: grey;
  margin-bottom: 35px;
  margin-top: 20px;
  font-size: 45px;
}

/* Project Cards */
.cardsMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px;
  justify-content: center;
}

.mainCard {
  margin: 20px;
}

.projectImg {
  height: 200px;
  width: 400px;
  align-self: flex-end;
  cursor: pointer;
}

.projectName {
  color: white;
  font-size: 30px;
  margin-top: 20px;
}

.sub-desc {
  color: rgb(196, 190, 190);
  padding-top: 1rem;
  
}

@media screen and (max-width: 1024px) {
  .cardsMain {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 50px;
  }
  .projectImg {
    height: 150px;
    width: 300px;
    align-self: flex-end;
    cursor: pointer;
  }
}
/* Project Cards */

/* Projects Page */

/* Skills Page */
.skillsMain {
}
.skillsTitle {
  color: white;
  font-size: 60px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin-top: 0px;
  margin-bottom: 100px;
}

.skillsChip {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin-left: 100px;
  margin-top: 100px;
  color: grey;
  margin-bottom: 0;
}

.skillsCards {
  color: white;
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  margin-left: 150px;
  margin-right: 150px;
  justify-content: center;
  align-self: center;
}

.individualCard {
  margin: 10px;
  font-size: 20px;
  margin-right: 50px;
  color: white;
  background-color: grey;
  display: flex;
  flex-direction: row;
  min-width: 400px;
}

@media screen and (max-width: 1024px) {
  .individualCard {
    margin: 10px;
    font-size: 12px;
    color: white;
    background-color: grey;
    display: flex;
    flex-direction: row;
    min-width: 280px;
  }
  .checkBox {
    justify-content: center;
    position: relative;
    top: -15px;
  }
}

.checkBox {
  margin-top: 35px;
  margin-right: 40px;
  margin-left: 20px;
}

/* Skills Page */

/* Contact Page */
.contactMain {
  color: white;
  font-size: 60px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin-top: 100px;
  margin-bottom: 100px;
}

.nameField {
  height: 40px;
  width: 200px;
}

/* Contact Page */
.title {
  color: white;
  font-size: 60px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 100px;
}

.phoneIcon {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  color: grey;
  margin-top: 175px;
}
.contactMe {
  color: white;
  font-size: 60px;
  justify-content: center;
  align-self: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin-top: 75px;
  margin-bottom: 100px;
}

.mainContacts {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
}

.mainContact {
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  align-self: flex-end;
  flex-grow: 1;
}

.contactIcon {
  background: grey;
  padding: 30px;
  border-radius: 50%;
  margin-bottom: 40px;
  height: 50px;
  width: 50px;
}

.contactIconPhone {
  background: grey;
  padding: 30px;
  border-radius: 50%;
  margin-bottom: 40px;
  height: 50px;
  width: 50px;
}

.contactName {
  font-size: 40px;
  margin-bottom: 15px;
}

.linkOrContactDetails {
  text-decoration: underline;
}


.linkOrContactDetails:hover,
.contactIcon:hover {
  color: #989494;
  cursor: pointer;
}

/* Contact Page */
